import { Box, Header1, Header2, Icon, Label, Panel, Text } from '~design-system'
import { useGetEnums } from '~hooks'

import { InformationItem, ServiceImage } from './components'
import { MetaContainer, SubTitle } from './styles'
import PanelsContainer from './styles/PanelsContainer'

type Props = {
  service: Service
}

const ServiceDetail = ({ service }: Props) => {
  const { getServiceEnumLabel } = useGetEnums()
  return (
    <>
      <Box di="f" ai="center" mb="4">
        <ServiceImage url={service.image}>
          {!service.image && (
            <Icon name="formation" size="bigger" color="primary" />
          )}
        </ServiceImage>
        <Header2 as={Header1}>{service.partner}</Header2>
      </Box>
      <MetaContainer>
        <Box>
          <Label mb={2}>Intitulé du service</Label>
          <p>{service.name}</p>
        </Box>
        <Box>
          <Label mb={2}>URL du service</Label>
          <p>
            <a href={service.url} target="_blank" rel="noreferrer">
              {service.url}
            </a>
          </p>
        </Box>
        <Box>
          <Label mb={2}>Statut</Label>
          <p>{getServiceEnumLabel('status', service.status)}</p>
        </Box>
      </MetaContainer>
      <PanelsContainer>
        <Panel f={3}>
          <Box mb={3}>
            <SubTitle>À propos du service</SubTitle>
          </Box>
          <Text textColor="greyDarkest">{service.description}</Text>
        </Panel>
        <Panel f={1} p="0" minW={64}>
          <Box mb={3} pt={6} pr={6} pl={6}>
            <SubTitle>Informations</SubTitle>
          </Box>
          <InformationItem
            icon="clock-full"
            label="Temps à consacrer"
            content={service.timeToSpend}
          />
          <InformationItem
            icon="birthday"
            label="Public ciblé"
            content={service.targetAudience}
          />
          {service.address?.properties?.city && (
            <InformationItem
              icon="poi"
              label="Localisation"
              content={service.address?.properties.city}
            />
          )}
          <InformationItem
            icon="faq"
            label="Catégorie du service"
            content={service.category}
          />
        </Panel>
      </PanelsContainer>
    </>
  )
}

export default ServiceDetail
