import { useQuery } from 'react-query'

import { HaloService } from '~api'

function useServices() {
  const useGetService = (id?: number) => {
    return useQuery(['services', id], () => HaloService.apiGetService(id), {
      enabled: !!id,
    })
  }

  const useGetServices = (filters?: string) =>
    useQuery(['services', filters], () => HaloService.apiGetServices(filters))

  return {
    useGetService,
    useGetServices,
  }
}

export default useServices
